import React from 'react';

const ImageGrid = () => {
  const images = [
    {
      src: '/1.webp',
      alt: 'Image 1'
    },
    {
      src: '/2.webp',
      alt: 'Image 2'
    },
    {
      src: '/3.webp',
      alt: 'Image 3'
    },
    {
      src: '/4.webp',
      alt: 'Image 4'
    }
  ];

  const clientData = {
    client: 'Discover NI',
    industry: 'Tourism & travel',
    year: '2023',
    work: 'Photography, marketing'
  };

  return (
    <div className="flex justify-center w-full px-4 py-8 md:py-8 lg:py-8">
      <div className="w-full max-w-3xl space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <img
              src={images[0].src}
              alt={images[0].alt}
              className="w-full h-full object-cover rounded-lg shadow"
            />
          </div>
          <div>
            <img
              src={images[1].src}
              alt={images[1].alt}
              className="w-full h-full object-cover rounded-lg shadow"
            />
          </div>
          <div>
            <img
              src={images[2].src}
              alt={images[2].alt}
              className="w-full h-full object-cover rounded-lg shadow"
            />
          </div>
          <div>
            <img
              src={images[3].src}
              alt={images[3].alt}
              className="w-full h-full object-cover rounded-lg shadow"
            />
          </div>
        </div>
        <div className="bg-white p-4 border-2 border-black rounded-md shadow-lg">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-gray-500 text-xs mb-1">CLIENT</p>
              <p className="text-black text-sm font-medium">{clientData.client}</p>
            </div>
            <div>
              <p className="text-gray-500 text-xs mb-1">INDUSTRY</p>
              <p className="text-black text-sm font-medium">{clientData.industry}</p>
            </div>
            <div>
              <p className="text-gray-500 text-xs mb-1">YEAR</p>
              <p className="text-black text-sm font-medium">{clientData.year}</p>
            </div>
            <div>
              <p className="text-gray-500 text-xs mb-1">WORK</p>
              <p className="text-black text-sm font-medium">{clientData.work}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageGrid;