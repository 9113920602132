import React from 'react';

const Ppvdh = () => {
  const imageData = [
    {
      src: '/pp.webp',
      alt: 'pride & pinion',
      client: 'Pride & Pinion',
      industry: 'Ecommerce',
      year: '2023',
      work: 'Website design, marketing'
    },
    {
      src: '/vdh.webp',
      alt: 'nico',
      client: 'Nico Leonard',
      industry: 'Luxury watches',
      year: '2024',
      work: 'Website design, branding'
    }
  ];

  return (
    <div className="flex justify-center w-full px-4 py-8 md:py-8 lg:py-8 ">
      <div className="w-full max-w-7xl flex flex-col md:flex-row md:justify-between md:space-x-4 space-y-8 md:space-y-0">
        {imageData.map((image, index) => (
          <div key={index} className="flex-1 flex flex-col space-y-4">
            <img
              src={image.src}
              alt={image.alt}
              className="rounded-lg shadow w-full h-auto object-cover"
            />
            <div className="w-full bg-white p-4 border-2 border-black rounded-md shadow-lg">
              <div className="grid grid-cols-2 gap-4">
                {Object.entries(image).map(([key, value]) => (
                  key !== 'src' && key !== 'alt' && (
                    <div key={key}>
                      <p className="text-gray-500 text-xs mb-1">{key.toUpperCase()}</p>
                      <p className="text-black text-sm font-medium">{value}</p>
                    </div>
                  )
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Ppvdh;