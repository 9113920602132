import React from 'react';

const ImageGrid = () => {
  const images = [
    {
      src: '/DSC08509.png',
      alt: 'Image 1'
    },
    {
      src: '/DSC08541.png',
      alt: 'Image 2'
    },
    {
      src: '/DSC08493.png',
      alt: 'Image 3'
    }
  ];

  return (
    <div className="w-full max-w-3xl space-y-4 mt-4 mb-4">
      <div className="grid grid-cols-2 gap-4">
        <div className="row-span-2">
          <img
            src={images[0].src}
            alt={images[0].alt}
            className="w-full h-full object-cover rounded-lg shadow"
          />
        </div>
        <div>
          <img
            src={images[1].src}
            alt={images[1].alt}
            className="w-full h-full object-cover rounded-lg shadow"
          />
        </div>
        <div>
          <img
            src={images[2].src}
            alt={images[2].alt}
            className="w-full h-full object-cover rounded-lg shadow"
          />
        </div>
      </div>
    </div>
  );
};

const Duesenberg = () => {

  const videoData = {
    alt: 'duesenberg guitars',
    client: 'Duesenberg Guitars',
    industry: 'Instruments & music',
    year: '2024',
    work: 'Videography & photography'
  };

  return (
    <div className="flex justify-center w-full py-8">
      <div className="w-full max-w-3xl">
        <ImageGrid />
        <div className="w-full bg-white p-4 border-2 border-black rounded-md shadow-lg mt-4 text-left">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-gray-500 text-xs mb-1">CLIENT</p>
              <p className="text-black text-sm font-medium">{videoData.client}</p>
            </div>
            <div>
              <p className="text-gray-500 text-xs mb-1">INDUSTRY</p>
              <p className="text-black text-sm font-medium">{videoData.industry}</p>
            </div>
            <div>
              <p className="text-gray-500 text-xs mb-1">YEAR</p>
              <p className="text-black text-sm font-medium">{videoData.year}</p>
            </div>
            <div>
              <p className="text-gray-500 text-xs mb-1">WORK</p>
              <p className="text-black text-sm font-medium">{videoData.work}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Duesenberg;