import React from 'react';
import './fonts.css';

import Header from './components/header';
import Dale from './components/dale';
import About from './components/about';
import Duesenberg from './components/duesenberg';
import MoreAbout from './components/moreabout';
import Ppvdh from './components/ppvdh';
import Prices from './components/prices';
import Invest from './components/investni';
import Contact from './components/contact';
import Toucan from './components/toucan';
import Bye from './components/bye';

function App() {
  return (
    <div className="App">
      <Header />
      <Dale />
      <About />
      <Duesenberg />
      <MoreAbout />
      <Ppvdh />
      <Prices />
      <Invest />
      <Contact />
      <Toucan />
      <Bye />
    </div>
  );
}

export default App;