import React from 'react';

const Header = () => {
  const scrollToPricing = () => {
    const pricingSection = document.getElementById('our-pricing');
    if (pricingSection) {
      pricingSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="flex justify-center w-full px-4 py-8 md:py-8 lg:py-8">
      <div className="w-full max-w-xl flex flex-col items-start">
        <div className="flex items-center mb-8">
          <img 
            src="/AP_Logo.webp" 
            alt="Alder Park Logo" 
            className="h-10 sm:h-12 md:h-14 lg:h-16 w-auto mr-3"
          />
          <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-black font-indie">
            alder park
          </h1>
        </div>
        <p className="font-bold text-sm sm:text-base md:text-lg lg:text-xl mb-3">
          <span>your design & production team, hassle-free.</span>
        </p>
        <p className="text-sm sm:text-base md:text-base lg:text-lg mb-3">
          <span className="text-black">Our creations help you </span>
          <span className="text-black">increase conversion, build your brand and launch your ideas fast</span>.
        </p>
        <p className="text-sm sm:text-base md:text-base lg:text-lg mb-3">
          <span className="text-black">We've previously worked for </span>
          <span className="text-black">Invest NI, Dale Farm, Porsche, NASA, Pride & Pinion </span>
          <span className="text-black">and many more teams over the last 5 years.</span>
        </p>
        <div className="flex flex-row space-x-3">
          <a href="https://calendly.com/alderparkconor/30min" target="_blank" rel="noopener noreferrer">
            <button className="btn book-call-btn bg-[#34abcb] text-white">
              <span className="relative z-10">Book a call</span>
            </button>
          </a>
          <button 
            onClick={scrollToPricing}
            className="btn see-pricing-btn bg-white text-black border-2 border-black"
          >
            <span className="relative z-10">See pricing</span>
          </button>
        </div>
      </div>

      <style jsx>{`
        .btn {
          padding: 0.5rem 1rem;
          font-size: 0.875rem;
          width: 120px;
          height: 40px;
          border-radius: 9999px;
          position: relative;
          overflow: hidden;
          transition: all 0.3s ease-in-out;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .btn:hover {
          transform: translateY(-2px);
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }

        .book-call-btn::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: black;
          transform: translateY(100%);
          transition: transform 0.3s ease-in-out;
          z-index: 0;
        }

        .book-call-btn:hover::before {
          transform: translateY(0);
        }

        .book-call-btn:hover span {
          color: white;
        }

        .see-pricing-btn:hover {
          background-color: #f0f0f0;
        }
      `}</style>
    </div>
  );
};

export default Header;