import React from 'react';

const PricingCard = ({ title, desc, price, features, ctaText, isFirst, isLast, isFreelance, buttonLink }) => (
  <div className={`rounded-lg p-8 w-full max-w-sm border-2 border-black shadow-xl
    ${isFirst ? "bg-white" : isLast ? "bg-white" : "bg-white"}`}
  >
    <div className="flex justify-between items-center mb-4">
      <h2 className={`text-2xl font-bold ${isFirst ? "text-black" : "text-black"}`}>{title}</h2>
    </div>
    <p className="mb-4 text-gray-700">
      {desc}
    </p>
    <div className={`text-4xl font-bold mb-6 ${isFirst ? "text-black" : "text-black"}`}>
      £{price} {!isFreelance && <span className="text-lg font-normal">/ per month</span>}
    </div>
    <ul className="space-y-2 mb-6">
      {features.map((feature, index) => (
        <li key={index} className="flex items-center text-gray-700">
          <svg className={`w-5 h-5 mr-2 ${isFirst ? "text-[#ff7640]" : "text-black"}`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="10" strokeWidth="2" />
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4" />
          </svg>
          {feature}
        </li>
      ))}
    </ul>
    <a href={buttonLink} target="_blank" rel="noopener noreferrer" className="block w-full">
      <button className={`w-full font-bold py-2 px-4 rounded overflow-hidden relative
        ${isFirst 
          ? "bg-[#ff7640] text-white hover:bg-black transition-colors duration-300" 
          : isLast
            ? "bg-black text-white border-2 border-black hover:bg-transparent hover:text-black transition-colors duration-300"
            : "bg-[#34abcb] text-white hover:bg-black transition-colors duration-300"
        }`}
      >
        {ctaText}
      </button>
    </a>
  </div>
);

const Prices = () => {
  const designSupportFeatures = [
    '2 requests per week',
    'Videography & photography',
    'Marketing',
    'Design',
    'Social media',
    'No contracts, cancel anytime'
  ];

  const designStudioFeatures = [
    'Everything in Starter +',
    '3 requests per week',
    'Daily support',
    'Strategy consultations',
    'Ecommerce support',
    'No contracts, cancel anytime'
  ];

  const designProFeatures = [
    'One request for any of the following;',
    'Videography',
    'Photography',
    'Design',
    'Marketing',
    'Social media'
  ];

  return (
    <div id="our-pricing" className="flex justify-center w-full px-4 py-8 md:py-8 lg:py-8">
      <div className="w-full max-w-6xl space-y-8 md:space-y-12">
        <div className="max-w-xl mx-auto text-left space-y-4">
          <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold">No hidden fees, same price and value every month, with the freedom to cancel anytime.</p>
          <p className="text-sm sm:text-base md:text-base lg:text-lg">
            <span className="text-black">We offer a </span>
            <span className="text-black">monthly subscription for ongoing support that you can pause or cancel at anytime, alternatively, we also have a one-off negotiable fee if you just need one request done fast.</span>
          </p>
        </div>
        <div className="flex flex-col md:flex-row justify-center items-center md:items-stretch gap-6 w-full">
          <PricingCard 
            title="Starter" 
            desc="For businesses or teams needing quick design & production turnaround."
            price="1,800" 
            features={designSupportFeatures} 
            ctaText="Start now" 
            isFirst={true}
            isLast={false}
            isFreelance={false}
            buttonLink="https://buy.stripe.com/5kA5nq3gg5KG4nufYY"
          />
          <PricingCard 
            title="Studio" 
            desc="For large scale businesses or teams needing a full design & production team."
            price="3,200" 
            features={designStudioFeatures} 
            ctaText="Create today" 
            isFirst={false}
            isLast={false}
            isFreelance={false}
            buttonLink="https://buy.stripe.com/6oEg246ssb504nu7st"
          />
          <PricingCard 
            title="Freelance" 
            desc="For business and teams needing a one-off design or production request."
            price="Custom" 
            features={designProFeatures} 
            ctaText="Book a call" 
            isFirst={false}
            isLast={true}
            isFreelance={true}
            buttonLink="https://calendly.com/alderparkconor/30min"
          />
        </div>
      </div>
    </div>
  );
};

export default Prices;