import React from 'react';
import Marquee from "react-fast-marquee";

const Bye = () => {
  return (
    <div className="w-full bg-gray-100 py-8 overflow-hidden">
      <span className="text-3xl sm:text-5xl md:text-7xl lg:text-9xl text-gray-600 font-bold">
        <Marquee speed={100} gradientWidth={0}>
          <span className="inline-block px-8">THANKS FOR READING, WE HOPE TO HEAR FROM YOU SOON. GOODBYE!!</span>
        </Marquee>
      </span>
    </div>
  );
};

export default Bye;