import React from 'react';

const MoreAbout = () => {
  return (
    <div className="flex justify-center w-full px-4 py-8 md:py-8 lg:py-8">
      <div className="w-full max-w-xl flex flex-col items-start space-y-4">
        <p className="text-sm sm:text-base md:text-base lg:text-lg">
          <span className="text-black">Just need a quick fix? </span>
          <span className="text-black">Full marketing campaign? Social media posts? We are always available.</span>
        </p>
        <p className="text-sm sm:text-base md:text-base lg:text-lg">
          <span className="text-black">Whatever your needs, we are here to help. Over 30+ teams and businesses have trusted us to deliver and were confident you can too.</span>
        </p>
        <p className="text-sm sm:text-base md:text-base lg:text-lg">
          <span className="text-black">If you're not happy with our work, we'll keep going until you are. We are hassle-free, flexible and available for any request you have.</span>
        </p>
        {/* Commented out button
        <div className="flex flex-row space-x-4">
          <a href="https://calendly.com/alderparkconor/30min" target="_blank" rel="noopener noreferrer">
            <button className="btn book-call-btn bg-green-600 text-white hover:bg-black">
              <span className="relative z-10">Book a call</span>
            </button>
          </a>
        </div>
        */}
      </div>
      <style jsx>{`
        .btn {
          padding: 0.5rem 1rem;
          font-size: 0.875rem;
          width: 120px;
          height: 40px;
          border-radius: 9999px;
          position: relative;
          overflow: hidden;
          transition: all 0.3s ease-in-out;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .book-call-btn:hover {
          transform: translateY(-2px);
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }
      `}</style>
    </div>
  );
};

export default MoreAbout;