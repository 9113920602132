import React from 'react';
import Srcga from './srcga';

const Contact = () => {
  const peopleData = [
    {
      src: '/c.webp',
      alt: 'conor',
      name: 'Conor',
      job: 'Design',
      favoriteFood: 'Oysters',
      petPeeve: 'Electric stoves'
    },
    {
      src: '/g.webp',
      alt: 'gareth',
      name: 'Gareth',
      job: 'Photography',
      favoriteFood: 'Broccoli',
      petPeeve: 'People scared of rain'
    },
    {
      src: '/j.webp',
      alt: 'jonny',
      name: 'Jonny',
      job: 'Videography',
      favoriteFood: 'Pizza',
      petPeeve: 'Rain'
    }
  ];

  return (
    <div className="w-full space-y-8">
      <div className="flex flex-col space-y-8 py-8">
        <div className="flex justify-center w-full px-4">
          <div className="w-full max-w-3xl flex flex-col items-center space-y-6">
            <p className="text-center">
              <span className="font-bold text-base sm:text-lg md:text-xl lg:text-2xl">Still on the fence?</span>{' '}
              <span className="text-base sm:text-lg md:text-lg lg:text-xl">Book a call with us and we <br/>can answer all your questions.</span>
            </p>
            <div className="flex flex-row space-x-4">
              <a href="https://calendly.com/alderparkconor/30min" target="_blank" rel="noopener noreferrer">
                <button className="btn book-call-btn bg-orange-600 text-white hover:bg-orange-700">
                  <span className="relative z-10">Book a call</span>
                </button>
              </a>
              <a href="https://buy.stripe.com/5kA5nq3gg5KG4nufYY" target="_blank" rel="noopener noreferrer">
                <button className="btn start-now-btn bg-white text-black border-2 border-black hover:bg-gray-100">
                  <span className="relative z-10">Start now!</span>
                </button>
              </a>
            </div>
          </div>
        </div>
        
        {/* <div className="w-full bg-gray-100 py-8 px-4">
          <div className="max-w-7xl mx-auto space-y-3">
            <p className="text-xl sm:text-2xl text-gray-600 text-center">ALTERNATIVELY YOU CAN EMAIL US</p>
            <a href="mailto:hello@alderpark.uk" className="block font-bold text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-center hover:text-gray-700 transition-all duration-200 transform hover:scale-105">
              helloalderpark@gmail.com
            </a>
          </div>
        </div> */}
      </div>

      <Srcga />

      <div className="flex flex-col space-y-8 py-8">
        <div className="flex justify-center w-full px-4">
          <div className="w-full max-w-xl flex flex-col items-start space-y-3">
            <p className="text-sm sm:text-base md:text-base lg:text-lg">
              <span>We're a small team of 3 who just enjoy making cool stuff. We're far more interested in doing than discussing, so we try to make it as easy as possible to get the work you need done.</span>
            </p>
            <p className="text-sm sm:text-base md:text-base lg:text-lg">
              <span className="text-black">We're yet to find a idea we can't build or a business we couldn't help, and we have no plans to start anytime soon.</span>
            </p>
          </div>
        </div>

        <div className="px-4 py-8">
          <div className="flex flex-col md:flex-row md:justify-between md:space-x-4 space-y-8 md:space-y-0">
            {peopleData.map((person, index) => (
              <div key={index} className="flex flex-col items-center flex-1">
                <div className="w-48 h-48 md:w-56 md:h-56 lg:w-64 lg:h-64 relative overflow-hidden mb-3">
                  <img
                    src={person.src}
                    alt={person.alt}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="w-full max-w-sm bg-white p-4 border-2 border-black rounded-md shadow-lg">
                  <div className="grid grid-cols-2 gap-3">
                    <div>
                      <p className="text-gray-500 text-xs mb-1">NAME</p>
                      <p className="text-black text-sm font-medium">{person.name}</p>
                    </div>
                    <div>
                      <p className="text-gray-500 text-xs mb-1">JOB</p>
                      <p className="text-black text-sm font-medium">{person.job}</p>
                    </div>
                    <div>
                      <p className="text-gray-500 text-xs mb-1">FAV FOOD</p>
                      <p className="text-black text-sm font-medium">{person.favoriteFood}</p>
                    </div>
                    <div>
                      <p className="text-gray-500 text-xs mb-1">PET PEEVE</p>
                      <p className="text-black text-sm font-medium">{person.petPeeve}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <style jsx>{`
        .btn {
          padding: 0.5rem 1rem;
          font-size: 0.875rem;
          width: 120px;
          height: 40px;
          border-radius: 9999px;
          position: relative;
          overflow: hidden;
          transition: all 0.3s ease-in-out;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .book-call-btn:hover,
        .start-now-btn:hover {
          transform: translateY(-2px);
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }
      `}</style>
    </div>
  );
};

export default Contact;