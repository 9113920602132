import React, { useState, useRef } from 'react';
import { Volume2, VolumeX } from 'lucide-react';

const Dale = () => {
  const [isMuted, setIsMuted] = useState(true);
  const videoRef = useRef(null);

  const videoData = {
    src: '/dalefarm.mp4',
    alt: 'dale farm',
    client: 'Dale Farm',
    industry: 'Farming & sustainability',
    year: '2024',
    work: 'Videography'
  };

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  return (
    <div className="flex justify-center w-full py-8">
      <div className="w-full max-w-3xl">
        <div className="relative w-full">
          <video
            ref={videoRef}
            src={videoData.src}
            alt={videoData.alt}
            className="w-full h-auto rounded-lg shadow"
            autoPlay
            muted
            loop
            playsInline
          />
          <button
            onClick={toggleMute}
            className="absolute top-2 right-2 p-2 bg-black bg-opacity-50 rounded-full text-white hover:bg-opacity-75 transition-colors"
          >
            {isMuted ? <VolumeX size={14} /> : <Volume2 size={14} />}
          </button>
        </div>
        <div className="w-full bg-white p-4 border-2 border-black rounded-md shadow-lg mt-4 text-left">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-gray-500 text-xs mb-1">CLIENT</p>
              <p className="text-black text-sm font-medium">{videoData.client}</p>
            </div>
            <div>
              <p className="text-gray-500 text-xs mb-1">INDUSTRY</p>
              <p className="text-black text-sm font-medium">{videoData.industry}</p>
            </div>
            <div>
              <p className="text-gray-500 text-xs mb-1">YEAR</p>
              <p className="text-black text-sm font-medium">{videoData.year}</p>
            </div>
            <div>
              <p className="text-gray-500 text-xs mb-1">WORK</p>
              <p className="text-black text-sm font-medium">{videoData.work}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dale;