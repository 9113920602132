import React from 'react';

const About = () => {
  const tags = [
    { text: 'design', icon: '✏️' },
    { text: 'videography', icon: '🎥' },
    { text: 'photography', icon: '📷' },
    { text: 'branding', icon: '🏷️' },
    { text: 'ecommerce', icon: '🛒' },
    { text: 'marketing', icon: '🚀' },
    { text: 'websites', icon: '🖥️' },
    { text: 'user experience', icon: '🧩' },
    { text: 'social media', icon: '📱' },
  ];

  return (
    <div className="flex justify-center w-full px-4 py-8 md:py-8 lg:py-8">
      <div className="w-full max-w-xl flex flex-col items-start space-y-4">
        <p className="text-sm sm:text-base md:text-base lg:text-lg">
          <span>We have experience in ecommerce, real estate, farming, sustainability, education, clothing, food, beverages and healthcare.</span>
        </p>
        <p className="text-sm sm:text-base md:text-base lg:text-lg">
          <span className="text-black">We've scaled with brands to well over 600M+ impressions and £10M+ annual revenue. We plan, design and execute consistently returning strategies for our clients focusing on short term impact and long term growth.</span>
        </p>
        <div className="flex flex-wrap gap-2 justify-center w-full">
          {tags.map((tag, index) => (
            <span
              key={index}
              className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-purple-100 text-purple-800"
            >
              {tag.icon} {tag.text}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default About;